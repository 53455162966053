import { t, Trans } from '@lingui/macro';
import {
  Button2,
  Field2,
  InputText2,
  Modal,
  ModalBody,
  ModalProps,
  PaperAirplaneIcon,
  useGraphqlClient,
  useMutation,
} from '@milo/react';
import { ApiResponse } from '@milo/types';
import { validate as isValidEmail } from 'email-validator';
import { FC, FormEvent, useState } from 'react';
import { ForgotCredentialsMutation } from './types';

export const ModalForgotCredentials: FC<ModalProps> = ({ onRequestClose = () => {}, ...props }) => {
  const { client } = useGraphqlClient();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<string>();

  const {
    mutate: sendUsername,
    isLoading,
    isSuccess: sent,
  } = useMutation<ApiResponse, Error, ForgotCredentialsMutation>(
    async ({ email }) => {
      const response = await client.request(
        /* GraphQL */ `
          mutation PartnerRemindCredentials($input: EmailInput!) {
            PartnerRemindCredentials(input: $input) {
              success
              data
            }
          }
        `,
        { input: { email } }
      );

      return response?.PartnerRemindCredentials;
    },
    {
      onMutate: () => {
        setEmailError(undefined);
      },
      onSuccess: ({ success }) => {
        if (success) {
          return;
        }
      },
      onError: () => {
        setEmailError(
          'Ce courriel n’est attaché à aucun partenaire M ta région. Veuillez renseigner un autre courriel.'
        );
      },
    }
  );

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    sendUsername({ email });
  };

  const censoredEmail = email.split('@')[0] + '****';

  return (
    <Modal {...props} onRequestClose={onRequestClose}>
      <ModalBody>
        {sent ? (
          <>
            <PaperAirplaneIcon className="mx-auto h-16 w-16" />
            <h1 className="mb-3 mt-4 text-center text-[28px] font-bold leading-8">
              <Trans>Identifiants envoyés</Trans>
            </h1>
            <p className="text-center text-medium-dark">
              <Trans>
                Vos identifiants de connexion ont été envoyés sur <strong className="font-bold">{censoredEmail}</strong>
                . Suivez les instructions pour vous connecter.
              </Trans>
            </p>
            <Button2 type="submit" block onClick={() => onRequestClose()} className="mt-10">
              <Trans>Retourner à la connexion</Trans>
            </Button2>
            <Button2 variant="tertiary" type="submit" block onClick={() => sendUsername({ email })} className="mt-3">
              <Trans>Renvoyer les identifiants</Trans>
            </Button2>
          </>
        ) : (
          <>
            <h1 className="mb-3 text-[28px] font-bold leading-9">
              <Trans>Récupérez vos identifiants de connexion</Trans>
            </h1>
            <p className="text-medium-dark">
              <Trans>
                Entrez un courriel administrateur sur lequel recevoir vos identifiants de connexion à votre espace
                partenaire.
              </Trans>
            </p>

            <form onSubmit={onSubmit} className="mt-10">
              <Field2 errorText={emailError}>
                <InputText2
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t`Entrez le courriel administrateur`}
                />
              </Field2>
              <Button2 disabled={!isValidEmail(email)} type="submit" block className="mt-3" loading={isLoading}>
                <Trans>Envoyer</Trans>
              </Button2>
            </form>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};
