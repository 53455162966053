import { ExperienceSelector } from '@/components/Partner';
import { t, Trans } from '@lingui/macro';
import {
  ArrowRight,
  Button2,
  EMAIL_ADDRESS_PARTNERS,
  Link,
  LinkButton,
  Loading,
  ReactFC,
  useLocalStorage,
  usePartnerAuthentication,
} from '@milo/react';

import { Experience, PartnerUser } from '@milo/types';
import { createContext, FC, FormEvent, ReactNode, useContext, useEffect, useState } from 'react';

export const PARTNER_CURRENT_EXP_KEY = 'partner-current-experience';

export const PartnerSubHeading: FC = ({ children }) => {
  const { setSubHeading } = useContext(PartnerContext);

  useEffect(() => {
    setSubHeading(children);
  }, []);

  return null;
};

export const PartnerContext = createContext(
  {} as {
    partnerUser: PartnerUser;
    isLoading: boolean;
    isError: boolean;
    selectedExperience: Experience | undefined;
    experiences: Experience[];
    selectExperience: (id: number) => void;
    subHeading?: ReactNode;
    setSubHeading: (content: ReactNode) => void;
  }
);

const PartnerUserContextProvider: ReactFC = ({ children }) => {
  const { partnerUser, isLoading: partnerLoading, isError, loggedIn } = usePartnerAuthentication();

  const availableExperiences = (partnerUser?.experiences ?? []) as Experience[];

  const [subHeading, setSubHeading] = useState<ReactNode>();
  const [experienceId, setExperienceId] = useLocalStorage(PARTNER_CURRENT_EXP_KEY);

  useEffect(() => {
    if (availableExperiences.length === 1) {
      setExperienceId(availableExperiences[0].id);
    }
  }, [availableExperiences.length]);

  if (partnerLoading) {
    return <Loading text={t`Chargement de votre espace partenaire...`} />;
  }

  if (!loggedIn) {
    location.href = '/partner/login';

    return null;
  }

  const experience = availableExperiences.find((experience) => Number(experience.id) === experienceId);

  if (!experience && availableExperiences.length > 0) {
    children = <ExperienceSelectorPage onExperienceSelected={(experience) => setExperienceId(experience.id)} />;
  }

  return (
    <PartnerContext.Provider
      value={{
        partnerUser,
        isLoading: partnerLoading,
        isError,
        selectedExperience: experience,
        selectExperience: setExperienceId,
        experiences: availableExperiences,
        subHeading,
        setSubHeading,
      }}
    >
      {children}
    </PartnerContext.Provider>
  );
};

export const PartnerPage: FC = ({ children }) => {
  return <PartnerUserContextProvider>{children}</PartnerUserContextProvider>;
};

type ExperienceSelectorPageProps = {
  onExperienceSelected?: (experience: Experience) => void;
};

const ExperienceSelectorPage: FC<ExperienceSelectorPageProps> = ({ onExperienceSelected = () => {} }) => {
  const { partnerUser: user } = usePartnerAuthentication();

  const experiences = (user?.experiences ?? []) as Experience[];

  const [selectedExperience, setSelectedExperience] = useState<Experience>();

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    onExperienceSelected(selectedExperience!);
  };

  if (experiences.length === 0) {
    return (
      <div className="flex h-full w-full max-w-md flex-col justify-center overflow-auto">
        <h6>
          <Trans>
            Désolé, le compte avec lequel vous tentez de vous connecter n'est soit pas valide ou n'a pas d'expériences
            en ligne actuellement.{' '}
            <Link
              className="text-raspberry"
              href={`mailto:${EMAIL_ADDRESS_PARTNERS}?subject=${encodeURIComponent(t`Une question pour M ta région`)}`}
            >
              Contactez-nous
            </Link>{' '}
            pour plus d'informations.
          </Trans>
        </h6>
        <LinkButton variant="secondary" href="">
          <Trans>Retour à la page d'accueil</Trans>
        </LinkButton>
      </div>
    );
  }

  return (
    <div className="relative -top-[10vh] flex h-screen flex-col justify-center overflow-auto">
      <div className="mb-10 py-1">
        <h1 className="mb-2.5 text-center text-2xl font-medium leading-6">
          <Trans>
            Bonjour <span className="text-raspberry">{user.group?.name ?? ''} 👋</span>
          </Trans>
        </h1>
        <h2 className="text-center text-[40px] font-bold leading-[52px]">
          <Trans>Choisissez l’expérience à afficher</Trans>
        </h2>
      </div>

      <form className="flex flex-col items-center space-y-10" onSubmit={onSubmit}>
        <ExperienceSelector
          selected={selectedExperience}
          experiences={experiences}
          onSelected={setSelectedExperience}
        />
        <Button2
          disabled={!selectedExperience}
          variant="dark"
          EndIcon={(props) => <ArrowRight {...props} className="h-4 w-4 text-white" />}
          type="submit"
        >
          <Trans>Connecter</Trans>
        </Button2>
      </form>
    </div>
  );
};
