import { PartnerContext } from '@components/Layout';
import { t, Trans } from '@lingui/macro';
import {
  ArrowLeft,
  ArrowRight,
  BrokenCloud,
  Button2,
  CheckCircle,
  Chevron,
  colorFn,
  EMAIL_ADDRESS_PARTNERS,
  ImageKitImage,
  Link,
  Modal,
  ModalBody,
  ModalCloseIcon,
} from '@milo/react';
import { Experience } from '@milo/types';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { ButtonHTMLAttributes, FC, FormEvent, ReactNode, useContext, useState } from 'react';
import { ExperienceSelector } from './ExperienceSelector';
import { ModalCreateAdminPassword } from './ModalCreateAdminPassword';

type PartnerHeaderProps = {
  back?: {
    link: ReactNode;
    text: ReactNode;
  };
  title?: ReactNode;
  bannerShown?: boolean;
};

export const PartnerHeader: FC<PartnerHeaderProps> = ({ back, title, bannerShown }) => {
  const {
    selectedExperience: experience,
    selectExperience,
    experiences,
    subHeading,
    partnerUser: partner,
  } = useContext(PartnerContext);
  const router = useRouter();

  const [showOnboardingSteps, setShowOnboardingSteps] = useState(false);
  const [showExperienceSelector, setShowExperienceSelector] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [showResetAdminPassword, setShowResetAdminPassword] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState<Experience>(experience!);

  // [Alex] I hate this... mais j'ai pas le choix pour le moment
  const isDashboard = router.pathname === '/partner/dashboard';

  const stepsDone = 1;
  const totalSteps = 5;
  const percentageDone = stepsDone / totalSteps;

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    selectExperience(selectedExperience!.id);
    setShowExperienceSelector(false);
    setShowAccountMenu(false);
  };

  return (
    <>
      <div className={`flex items-start justify-between px-8 py-10 ${isDashboard ? 'bg-gray-lighter' : 'bg-white'}`}>
        <div className="flex flex-col">
          {/* empty div is intentional if no back link and title is provided */}
          {back !== undefined && (
            <Link href={back.link} className="inline-flex items-center">
              <ArrowLeft className="mr-3 h-4 w-4 text-charcoal" fill={colorFn('charcoal')} />
              {back.text}
            </Link>
          )}
        </div>
        <button className="flex items-center" onClick={() => setShowAccountMenu(true)}>
          <div className="flex flex-col items-end space-y-1">
            <h5 className="text-xl font-medium leading-6">{experience.name}</h5>
            {experience?.status === 'online' ? (
              <p className="text-success">
                <Trans>Expérience active</Trans>
                <span className="ml-1 inline-flex h-2 w-2 rounded-full bg-success" />
              </p>
            ) : (
              <p className="text-medium-dark">
                <Trans>Expérience hors ligne</Trans>
                <BrokenCloud className="ml-1 inline-flex w-5 text-medium" />
              </p>
            )}
          </div>
          <ImageKitImage
            transforms={`w-56,h-56`}
            imageFileName={experience.main_image}
            className="ml-4 h-14 w-14 rounded"
          />
        </button>
        {stepsDone !== totalSteps && false && (
          <button
            className="relative -right-8 flex items-center space-x-5 rounded-l-lg bg-white px-6 py-5 shadow-lg"
            onClick={() => setShowOnboardingSteps(true)}
          >
            <p className="font-medium">
              <Trans>Complétez votre espace partenaire.</Trans>
            </p>
            <div className="h-2 w-16 flex-1 rounded-full bg-raspberry/30">
              <div className="h-2 rounded-full bg-raspberry" style={{ width: `${percentageDone * 100}%` }}></div>
            </div>
            <p className="font-medium text-medium-gray">
              <Trans>
                {stepsDone} / {totalSteps} étapes
              </Trans>
            </p>
          </button>
        )}
      </div>
      {(title !== undefined || subHeading !== undefined) && (
        <div className="mx-10 my-12">
          {title !== undefined && <h1 className="text-[40px] font-bold leading-[52px] ">{title}</h1>}
          {subHeading !== undefined && <p className="mt-2 text-xl leading-6">{subHeading}</p>}
        </div>
      )}
      {showAccountMenu && (
        <div>
          <button className="fixed inset-0 z-[2]" onClick={() => setShowAccountMenu(false)} />
          <div
            className={`absolute right-5 top-7 z-40 min-w-[320px] animate-fade-in-faster rounded-3xl bg-white p-3 shadow-lg`}
          >
            <div className="flex items-center justify-end">
              <div className="flex flex-col items-end space-y-1">
                <h5 className="text-xl font-medium leading-6">{experience.name}</h5>
                {experience?.status === 'online' ? (
                  <p className="text-success">
                    <Trans>Expérience active</Trans>
                    <span className="ml-1 inline-flex h-2 w-2 rounded-full bg-success" />
                  </p>
                ) : (
                  <p className="text-medium-dark">
                    <Trans>Expérience hors ligne</Trans>
                    <BrokenCloud className="ml-1 inline-flex w-5 text-medium" />
                  </p>
                )}
              </div>
              <ImageKitImage
                transforms={`w-56,h-56`}
                imageFileName={experience.main_image}
                className="ml-4 h-14 w-14 rounded"
              />
            </div>
            <div className="m-3 flex flex-col divide-y divide-gray-light">
              <div>
                <Link
                  onClick={(e) => {
                    if (experience?.status !== 'online') {
                      e.preventDefault();
                    }
                  }}
                  popup
                  href={`/experiences/${experience.slug}`}
                  className={`-mx-2 my-2 block rounded px-2 py-3 text-left text-medium-dark hover:bg-gray-lighter ${
                    experience?.status !== 'online' ? ' line-through' : ''
                  }`}
                >
                  <Trans>Voir l’expérience en ligne</Trans>
                </Link>
              </div>
              {experiences.length > 1 && (
                <div>
                  <Link // having it as a button f**ks up the width
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowExperienceSelector(true);
                    }}
                    className="-mx-2 my-2 block rounded px-2 py-3 text-left text-medium-dark hover:bg-gray-lighter"
                  >
                    <Trans>Afficher une autre expérience</Trans>
                  </Link>
                </div>
              )}
              <div>
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowResetAdminPassword(true);
                  }}
                  className="-mx-2 my-2 block rounded px-2 py-3 text-left text-medium-dark hover:bg-gray-lighter"
                >
                  {partner.has_admin_password ? (
                    <Trans>Changer le mot de passe administrateur</Trans>
                  ) : (
                    <Trans>Créer le mot de passe administrateur</Trans>
                  )}
                </Link>
              </div>
              <div>
                <Link
                  // @check-this
                  href={`mailto:${EMAIL_ADDRESS_PARTNERS}?subject=${encodeURIComponent(
                    t`Partenaire - Demande de contact`
                  )}`}
                  className="-mx-2 my-2 block rounded px-2 py-3 text-left text-medium-dark hover:bg-gray-lighter"
                >
                  <Trans>Nous contacter</Trans>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}

      <ModalCreateAdminPassword open={showResetAdminPassword} onRequestClose={() => setShowResetAdminPassword(false)} />

      <Modal open={showExperienceSelector} onRequestClose={() => setShowExperienceSelector(false)}>
        <ModalBody className="!max-w-4xl !px-14 !pb-20 !pt-24">
          <div className="flex flex-col items-center justify-center">
            <h2 className="mb-10 text-center text-[28px] font-bold leading-9">
              <Trans>Choisissez l’expérience à afficher</Trans>
            </h2>
            <form className="flex flex-col items-center space-y-10" onSubmit={onSubmit}>
              <ExperienceSelector
                selected={selectedExperience}
                onSelected={setSelectedExperience}
                experiences={experiences}
              />
              <Button2
                type="submit"
                disabled={!selectedExperience}
                variant="dark"
                onClick={() => selectExperience(selectedExperience.id)}
                EndIcon={ArrowRight}
              >
                <Trans>Valider</Trans>
              </Button2>
            </form>
          </div>
        </ModalBody>
      </Modal>
      {/* <PartnerOnboardingSteps isOpen={showOnboardingSteps} onClose={() => setShowOnboardingSteps(false)} /> */}
    </>
  );
};

export const PartnerOnboardingSteps = ({ isOpen = false, onClose = () => {} }) => {
  const containerClassName = classNames(
    'overflow-auto fixed z-[2] w-full top-0 bottom-0 max-w-md bg-white transition-all h-screen',
    {
      'right-0': isOpen,
      '-right-full': !isOpen,
    }
  );

  const overlayClassName = classNames('fixed transition-colors z-[1]', {
    'inset-0 w-screen h-screen bg-black bg-opacity-50': isOpen,
    'bg-opacity-0': !isOpen,
  });

  const stepsDone = 1;
  const totalSteps = 5;
  const percentageDone = stepsDone / totalSteps;

  return (
    <>
      <div className={overlayClassName} onClick={onClose} />
      <div className={containerClassName}>
        <div className="flex flex-col justify-center p-10">
          <div className="space-y-10">
            <div>
              <button onClick={onClose} className="mb-12">
                <ModalCloseIcon className="h-6 w-6" />
              </button>
              <div className="space-y-4">
                <h1 className="text-[28px] font-bold leading-9">
                  <Trans>Complétez votre espace partenaire</Trans>
                </h1>
                <p className="leading-[22px] text-medium-gray">
                  <Trans>
                    Accélérez votre publication d’expérience et soyez prêts à recevoir les membres M ta région dans
                    votre établissement.
                  </Trans>
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-12">
              <div className="h-2 flex-1 rounded-full bg-raspberry/30">
                <div className="h-2 rounded-full bg-raspberry" style={{ width: `${percentageDone * 100}%` }}></div>
              </div>
              <p className="text-xl font-medium leading-6">
                <Trans>
                  {stepsDone} / {totalSteps} étapes
                </Trans>
              </p>
            </div>
            <div className="space-y-4">
              <OnboardingStep done={true}>
                <Trans>Signez votre entente de partenariat</Trans>
              </OnboardingStep>
              <OnboardingStep warning={<Trans>Vos photos et votre description sont en cours d’approbation.</Trans>}>
                <Trans>Partagez vos meilleures photos</Trans>
              </OnboardingStep>
              <OnboardingStep>
                <Trans>Décrivez-nous votre expérience</Trans>
              </OnboardingStep>
              <OnboardingStep>
                <Trans>Apposez l’autocollant M ta région en vitrine</Trans>
              </OnboardingStep>
              <OnboardingStep>
                <Trans>Ajoutez le logo M ta région</Trans>
              </OnboardingStep>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

type OnboardingStepProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  done?: boolean;
  warning?: ReactNode;
};

const OnboardingStep: FC<OnboardingStepProps> = ({ done, warning, children, ...buttonProps }) => {
  return (
    <div>
      <button
        className={`flex w-full items-center justify-between rounded border border-gray-light p-4 text-left ${
          done ? 'cursor-default' : ''
        } space-x-4`}
        {...buttonProps}
        disabled={done}
      >
        <p className={`flex-1 ${done ? 'text-medium-gray' : 'text-charcoal'} leading-[22px]`}>{children}</p>
        <div>{done ? <CheckCircle className="h-6 w-6" /> : <Chevron className="h-6 w-6" />}</div>
      </button>
      {warning && (
        <div className="mt-2.5 rounded-lg bg-warning/10 p-3">
          <p className="text-sm leading-[18px] text-warning">{warning}</p>
        </div>
      )}
    </div>
  );
};
