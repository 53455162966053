import { t, Trans } from '@lingui/macro';
import { EMAIL_ADDRESS_PARTNERS, Link, ReactFC } from '@milo/react';
import { ReactNode } from 'react';
import { BaseLayout } from '.';
import { PartnerHeader } from '../Partner';
import { BaseLayoutProps } from './BaseLayout';
import { PartnerNavigation } from './PartnerNavigation';
import { PartnerPage } from './PartnerPage';

type PartnerBaseLayoutProps = BaseLayoutProps & {
  back?: {
    link: ReactNode;
    text: ReactNode;
  };
  headerTitle?: ReactNode;
};

export const PartnerBaseLayout: ReactFC<PartnerBaseLayoutProps> = ({
  showNavigation = true,
  back,
  headerTitle,
  children,
  ...props
}) => {
  return (
    <BaseLayout showNavigation={false} showFooter={false} showBanner={false} {...props}>
      <PartnerPage>
        <div className="flex h-screen max-h-screen flex-col">
          <NewPartnerSpaceBanner />
          <div className="relative flex w-full flex-1 flex-grow items-stretch overflow-hidden">
            {showNavigation && <PartnerNavigation />}
            <div className={`flex-1 ${showNavigation ? 'pl-[100px]' : ''} flex flex-col`}>
              {showNavigation && <PartnerHeader back={back} title={headerTitle} />}
              <div className="h-full flex-1 overflow-auto">{children}</div>
            </div>
          </div>
        </div>
      </PartnerPage>
    </BaseLayout>
  );
};

const NewPartnerSpaceBanner = () => {
  return null;
  return (
    <div className="relative z-[21] flex flex-shrink items-center bg-fushia p-3">
      <div />
      <div className="flex-1">
        <p className="text-center font-semibold leading-5 text-white">
          <Trans>Bienvenue dans votre nouvel espace partenaire, plus structuré, simple et intuitif ✨.</Trans>
        </p>
        <p className="text-center leading-5 text-white">
          <Trans>
            D’autres surprises s’en viennent. Question ou commentaire? Écrivez-nous à{' '}
            <Link
              href={`mailto:${EMAIL_ADDRESS_PARTNERS}?subject=${encodeURIComponent(
                t`Espace partenaire Beta & retours d'expérience`
              )}`}
              className="underline"
            >
              {EMAIL_ADDRESS_PARTNERS}
            </Link>
            .
          </Trans>
        </p>
      </div>
    </div>
  );
};
