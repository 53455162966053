import { t, Trans } from '@lingui/macro';
import {
  Button2,
  InputSelect2,
  InputText2,
  Modal,
  ModalBody,
  ModalProps,
  PaperAirplaneIcon,
  useGraphqlClient,
  useMutation,
  useQuery,
} from '@milo/react';
import { ApiResponse } from '@milo/types';
import { FC, FormEvent, useState } from 'react';
import { ForgotCredentialsMutation } from './types';

export const ModalForgotAdminPassword: FC<ModalProps> = ({ onRequestClose = () => {}, ...props }) => {
  const { client } = useGraphqlClient();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<string>();

  const {
    mutate: sendAdminPassword,
    isLoading,
    isSuccess: sent,
  } = useMutation<ApiResponse, Error, ForgotCredentialsMutation>(
    async ({ email }) => {
      const response = await client.request(
        /* GraphQL */ `
          mutation PartnerRemindAdminPassword($input: EmailInput!) {
            PartnerRemindAdminPassword(input: $input) {
              success
            }
          }
        `,
        { input: { email } }
      );

      return response?.PartnerRemindAdminPassword;
    },
    {
      onMutate: () => {
        setEmailError(undefined);
      },
      onSuccess: ({ success }) => {
        if (success) {
          return;
        }
      },
      onError: () => {
        setEmailError(
          'Ce courriel n’est attaché à aucun partenaire M ta région. Veuillez renseigner un autre courriel.'
        );
      },
    }
  );

  const { data: emails } = useQuery<string[]>(['partner-contacts'], async () => {
    const response = await client.request(/* GraphQL */ `
      query PartnerContactEmails {
        PartnerContactEmails {
          emails
        }
      }
    `);

    return response?.PartnerContactEmails.emails;
  });

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    sendAdminPassword({ email });
  };

  const censoredEmail = email.split('@')[0] + '****';

  return (
    <Modal {...props} onRequestClose={onRequestClose}>
      <ModalBody className="!max-w-2xl">
        {sent ? (
          <div className="px-32 py-8">
            <PaperAirplaneIcon className="mx-auto h-16 w-16" />
            <h1 className="mb-3 mt-4 text-center text-[28px] font-bold leading-8">
              <Trans>Mot de passe envoyé</Trans>
            </h1>
            <p className="text-center text-medium-dark">
              <Trans>
                Le mot de passe administrateur a été envoyé sur <strong className="font-bold">{censoredEmail}</strong>.
                Suivez les instructions pour vous connecter.
              </Trans>
            </p>
            <Button2 type="submit" block onClick={() => onRequestClose()} className="mt-10">
              <Trans>Retourner à la page</Trans>
            </Button2>
            <Button2
              variant="tertiary"
              type="submit"
              block
              onClick={() => sendAdminPassword({ email })}
              className="mt-3"
            >
              <Trans>Renvoyer les identifiants</Trans>
            </Button2>
          </div>
        ) : (
          <div className="px-32 py-24">
            <h1 className="mb-3 text-[28px] font-bold leading-9">
              <Trans>Récupérez le mot de passe administrateur</Trans>
            </h1>
            <p className="text-medium-dark">
              <Trans>
                Choisissez un courriel administrateur sur lequel recevoir le mot de passe administrateur à votre espace
                partenaire.
              </Trans>
            </p>

            <form onSubmit={onSubmit} className="mt-10">
              {emails ? (
                <InputSelect2
                  placeholder={t`Choisir un courriel administrateur`}
                  value={email}
                  onValueChange={setEmail}
                  items={emails.map((email) => ({ key: email, label: email, value: email }))}
                />
              ) : (
                <InputText2
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t`Entrez le courriel administrateur`}
                />
              )}
              <Button2 type="submit" block disabled={email === ''} className="mt-3" loading={isLoading}>
                <Trans>Envoyer</Trans>
              </Button2>
            </form>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};
