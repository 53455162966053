import { Trans } from '@lingui/macro';
import {
  Button2,
  Link2,
  LogoutIcon,
  MiloFull,
  MiloGuides,
  MiloMag,
  useAuthentication,
  UserCircle,
  useScrollHijack,
} from '@milo/react';
import { useState } from 'react';

type NavigationProps = {
  guides?: boolean;
};

export const Navigation = ({ guides = false }: NavigationProps) => {
  const { user } = useAuthentication();
  const [isOpen, setIsOpen] = useState(false);

  useScrollHijack({ isOpen, onEscapeKeyPress: () => setIsOpen(false) });

  const logoHref = '/';

  return (
    <div data-component={Navigation.name} className="py-6">
      <div className={`mx-auto flex w-full max-w-7xl items-center justify-between px-6`}>
        <Link2 href={logoHref}>
          <MiloFull height={35} />
        </Link2>

        {guides && <MiloGuides className="w-40" />}

        <div className="flex items-center space-x-8">
          {!isOpen && (
            <button
              data-testid="navigation-menu-button"
              className="cursor-pointer space-y-1.5"
              onClick={() => setIsOpen((isOpen) => !isOpen)}
            >
              <span className="block h-[3px] w-8 bg-dark" />
              <span className="block h-[3px] w-8 bg-dark" />
              <span className="block h-[3px] w-8 bg-dark" />
            </button>
          )}
        </div>
      </div>

      {isOpen && (
        <div className="fixed bottom-0 left-0 right-0 top-0 z-navigation animate-fade-in-fast bg-white">
          <div className="grid h-full grid-rows-[4.5rem_auto_4.5rem]">
            <div className={`mx-auto flex w-full max-w-7xl justify-between px-6 py-6`}>
              <Link2 href={logoHref}>
                <MiloFull height={35} className="left-0 top-6" />
              </Link2>
              <button
                className="right-1 top-7 h-6 w-6 cursor-pointer space-y-2"
                onClick={() => setIsOpen((isOpen) => !isOpen)}
              >
                <span className="block h-[3px] w-7 translate-y-[10.5px] -rotate-45 bg-dark"></span>
                <span className="block h-[3px] w-7 rotate-45 bg-dark "></span>
              </button>
            </div>

            <div className="w-full overflow-y-auto" style={{ boxShadow: 'rgb(0, 0, 0) 0px -55px 100px -125px inset' }}>
              <ul className="mx-auto flex max-w-7xl flex-col gap-3 space-y-3.5 px-6 pb-6 pt-8">
                <Link2 raw href="/">
                  <li className="text-lg font-medium">
                    <Trans>Accueil</Trans>
                  </li>
                </Link2>
                <Link2 raw href="/experiences">
                  <li className="text-lg font-medium">
                    <Trans>Répertoire des expériences</Trans>
                  </li>
                </Link2>
                <Link2 raw href="/map">
                  <li className="text-lg font-medium">
                    <Trans>Carte des expériences</Trans>
                  </li>
                </Link2>
                <Link2 raw href="/purchase">
                  <li className="text-lg font-medium">
                    <Trans>Acheter un pass M ta région</Trans>
                  </li>
                </Link2>
                <Link2 raw href="/mag-mtaregion">
                  <li className="text-lg font-medium">
                    <MiloMag className="h-5" />
                  </li>
                </Link2>
                {user?.id && (
                  <>
                    <div className="h-4 bg-gray-lighter" />
                    <Link2 raw href="/profile/wishlist">
                      <li className="text-lg font-medium">
                        <Trans>Listes de favoris</Trans>
                      </li>
                    </Link2>
                    <Link2 raw href="/profile/visits">
                      <li className="text-lg font-medium">
                        <Trans>Historique des découvertes</Trans>
                      </li>
                    </Link2>
                    <Link2 raw href="/profile">
                      <li className="text-lg font-medium">
                        <Trans>Compte et pass M ta région</Trans>
                      </li>
                    </Link2>
                    <Link2 raw href="/tutorials">
                      <li className="text-lg font-medium">
                        <Trans>Guide de l'utilisateur</Trans>
                      </li>
                    </Link2>
                  </>
                )}
                <div className="h-4 bg-gray-lighter" />
                <Link2 raw href="/about">
                  <li className="text-lg font-medium">
                    <Trans>À propos</Trans>
                  </li>
                </Link2>
                <Link2 raw href="/faq">
                  <li className="text-lg font-medium">
                    <Trans>Questions fréquentes</Trans>
                  </li>
                </Link2>
                <Link2 raw href="/faq">
                  <li className="text-lg font-medium">
                    <Trans>Nous joindre</Trans>
                  </li>
                </Link2>
                <>
                  <div className="h-4 bg-gray-lighter" />
                  <Link2 raw href="/partner/login">
                    <li className="text-lg font-medium">
                      <Trans>Espace partenaire</Trans>
                    </li>
                  </Link2>
                </>
              </ul>
            </div>
            <div className="flex w-screen items-center bg-gray-lighter px-6">
              <div className="mx-auto w-full max-w-7xl">
                {(!user?.id && (
                  <Link2 href="/login">
                    <Button2 size="sm">
                      <Trans>Se connecter</Trans>
                    </Button2>
                  </Link2>
                )) || (
                  <div className="flex items-center gap-3">
                    <Link2 href="/profile">
                      <button className="mr-2 flex items-center rounded-full border border-charcoal px-4 py-2 transition-all duration-300 hover:bg-charcoal hover:text-white">
                        <UserCircle width={22} className="mr-2 " />
                        <p className="mr-2 font-semibold">
                          <Trans>Profil</Trans>
                        </p>
                      </button>
                    </Link2>
                    <Link2 href="/logout">
                      <button className="flex items-center gap-2 rounded-full border border-raspberry px-4  py-2 font-semibold text-raspberry transition-all duration-300 hover:bg-red hover:text-white">
                        <LogoutIcon className="w-5" />
                        <Trans>Déconnexion</Trans>
                      </button>
                    </Link2>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
