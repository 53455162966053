import { ReactFC, ReactQueryProvider, ThemeProvider } from '@milo/react';
import { I18nProvider } from './I18nProvider';

export const Providers: ReactFC = ({ children }) => {
  return (
    <ThemeProvider>
      <I18nProvider>
        <ReactQueryProvider>{children}</ReactQueryProvider>
      </I18nProvider>
    </ThemeProvider>
  );
};
