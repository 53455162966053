import { Trans } from '@lingui/macro';
import { ArrowLeft } from '@milo/react';
import { useRouter } from 'next/router';
import { FC } from 'react';

export const BackButton: FC = ({ children }) => {
  const { back } = useRouter();

  return (
    <button className="flex items-center mb-6 space-x-3" onClick={back}>
      <ArrowLeft className="w-4 h-4" fill="currentColor" />
      <span className="font-medium">{children ?? <Trans>Retour</Trans>}</span>
    </button>
  );
};
