import { ImageKitImage } from '@milo/react';
import { Experience } from '@milo/types';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';

type ExperienceSelectorProps = {
  experiences: Experience[];
  selected?: Experience;
  onSelected?: (experience: Experience | undefined) => void;
};

export const ExperienceSelector: FC<ExperienceSelectorProps> = ({
  experiences,
  selected: initiallySelected,
  onSelected = () => {},
}) => {
  const [selectedExperience, setSelectedExperience] = useState(initiallySelected);

  const selectedClassName = classNames('border-raspberry');

  useEffect(() => {
    onSelected(selectedExperience);
  }, [selectedExperience]);

  return (
    <div className="flex items-stretch space-x-4">
      {experiences.map((experience) => (
        <button
          type="button"
          className={`w-40 border px-6 py-10 rounded flex flex-col items-center transition-all ${
            selectedExperience?.id === experience.id
              ? selectedClassName
              : 'border-gray-light hover:border-medium-gray hover:shadow-lg'
          } `}
          onClick={() => setSelectedExperience(experience)}>
          <ImageKitImage
            transforms={`w-56,h-56`}
            imageFileName={experience.main_image}
            className="mb-4 rounded w-14 h-14"
          />
          <h4 className="text-medium-dark font-semibold text-center mb-1 leading-5">{experience.name}</h4>
          <h4 className="text-medium font-semibold leading-[22px] text-center underline underline-offset-2">
            {experience?.location?.city}
          </h4>
        </button>
      ))}
    </div>
  );
};
