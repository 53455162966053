import { Trans } from '@lingui/macro';
import { MiloFullTransparent } from '@milo/react';
import { FC, ReactNode } from 'react';

type PartnerLoginLayoutProps = {
  header?: ReactNode;
  footer?: ReactNode;
};

export const PartnerLoginLayout: FC<PartnerLoginLayoutProps> = ({ header, footer, children }) => {
  return (
    <div className="flex h-screen max-h-screen items-stretch">
      <div
        className="flex w-[485px] flex-col justify-center bg-black bg-cover bg-center bg-no-repeat p-10"
        style={{ backgroundImage: `url(/images/partner/login-background.jpg)` }}
      >
        <div>
          <MiloFullTransparent className="mb-6 text-white" height={45} />
          <h1 className="relative w-min text-[64px] font-bold leading-none text-white">
            <Trans>Espace partenaire</Trans>
            <span className="absolute right-0 top-6 inline-flex items-center justify-center rounded bg-raspberry px-2 py-1 text-sm font-bold uppercase leading-6 text-white">
              <Trans>Beta</Trans>
            </span>
          </h1>
        </div>
      </div>
      <div className="flex max-h-screen flex-1 flex-col overflow-auto p-10">
        {header !== undefined && <header>{header}</header>}
        <div className="flex h-full flex-1 flex-col items-center justify-center">
          <main className="max-w-xs">{children}</main>
        </div>
        {footer !== undefined && <div>{footer}</div>}
      </div>
    </div>
  );
};
