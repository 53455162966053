import { t, Trans } from '@lingui/macro';
import {
  BookIcon,
  ChartIcon,
  CollapseIcon,
  DocumentIcon,
  EMAIL_ADDRESS_PARTNERS,
  ExpandIcon,
  HomeIcon,
  Link,
  LinkProps,
  LogoutIcon,
  MiloFullTransparent,
  Tooltip,
} from '@milo/react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { FC, ReactNode, useState } from 'react';

export const PartnerNavigation: FC = ({}) => {
  const [opened, setOpened] = useState(false);

  return (
    <div
      className={`absolute bottom-0 left-0 top-0 z-[20] flex max-w-md flex-col justify-between bg-charcoal py-8  ${
        opened ? 'w-full' : 'w-[100px]'
      } duration-250 transition-all`}
    >
      {/* Logo & exander */}
      <div>
        {opened ? (
          <div className="flex items-center justify-between px-8">
            <MiloFullTransparent className="w-28 text-white" fill="white" />
            <button className="ml-auto" type="button" onClick={() => setOpened(false)}>
              <CollapseIcon className="mx-auto w-7 text-medium-gray" />
            </button>
          </div>
        ) : (
          <div className="relative mx-auto h-[52px] w-[52px]">
            <button className="w-full" type="button" onClick={() => setOpened(true)}>
              <ExpandIcon className="mx-auto w-7 text-medium-gray" />
            </button>
          </div>
        )}

        <Tooltip text={<BetaTooltip />} placement="right-start" triggerOffset={48}>
          <div className={`flex flex-col pb-6 ${opened ? 'mx-8' : 'items-center'} group mt-4`}>
            <p>
              <span className="inline-flex justify-center whitespace-nowrap rounded bg-raspberry px-4 py-0.5 text-[12px] font-bold uppercase leading-5 text-white">
                {opened ? <Trans>Version Beta</Trans> : <Trans>Beta</Trans>}
              </span>
            </p>
            <p className="mt-4 font-medium leading-none text-white opacity-0 transition-opacity group-hover:opacity-100">
              <Link
                href={`
                mailto:${EMAIL_ADDRESS_PARTNERS}?subject=${encodeURIComponent(
                  t`Espace partenaire Beta retours expérience"`
                )}
                `}
                className="underline "
              >
                <Trans>Retours</Trans>
              </Link>
            </p>
          </div>
        </Tooltip>
      </div>

      {/* Menu Links */}
      <div className={`flex flex-col items-center justify-center space-y-6 ${opened ? 'px-6' : ''}`}>
        <NavigationLink
          expanded={opened}
          href="/partner/dashboard"
          icon={HomeIcon}
          active={(pathname) => pathname === '/partner/dashboard'}
        >
          <Trans>Octroyer une réduction-découverte</Trans>
        </NavigationLink>
        <NavigationLink expanded={opened} href="/partner/dashboard/stats" icon={ChartIcon}>
          <Trans>Statistiques</Trans>
        </NavigationLink>
        <NavigationLink expanded={opened} href="/partner/dashboard/partnership" icon={DocumentIcon}>
          <Trans>Entente de partenariat</Trans>
        </NavigationLink>
        {/* <NavigationLink expanded={opened} href="/partner/dashboard/settings" icon={CogIcon}>
          <Trans>Paramètres</Trans>
        </NavigationLink> */}
        <NavigationLink expanded={opened} href="/partner/guide" icon={BookIcon}>
          <Trans>Guide du Partenaire</Trans>
        </NavigationLink>
      </div>

      {/* Logout */}
      <div className={`flex flex-col items-center justify-center ${opened ? 'px-6' : ''}`}>
        <NavigationLink expanded={opened} href="/partner/logout" icon={LogoutIcon}>
          <Trans>Déconnexion</Trans>
        </NavigationLink>
      </div>
    </div>
  );
};

const BetaTooltip: FC = () => {
  return (
    <div className="max-w-sm">
      <h4 className="mb-4 text-sm font-bold leading-5 text-white">
        <Trans>Retours sur la Version Beta</Trans>
      </h4>
      <p className="text-sm leading-5 text-white">
        <Trans>
          Vous naviguez sur la version Beta du nouvel espace partenaire. Vous avez des questions ? Nous partager vos
          retours d’expérience ?
        </Trans>
      </p>
      <p className="mt-4 text-sm leading-5 text-white">
        <Trans>
          Écrivez-vous en cliquant sur <span className="underline">Retours</span>
        </Trans>
      </p>
    </div>
  );
};

type NavigationLinkProps = LinkProps & {
  icon: ReactNode;
  expanded?: boolean;
  active?: (currentPathname: string) => boolean;
};

const NavigationLink: FC<NavigationLinkProps> = ({
  expanded = false,
  icon,
  children,
  active: activeFn,
  ...linkProps
}) => {
  const router = useRouter();

  const active = activeFn ? activeFn(router.pathname) : router.pathname.startsWith(`/${linkProps.href}`);

  const linkCassName = classNames(
    'relative w-full flex items-center rounded-lg hover:bg-medium-dark transition-colors group',
    {
      'w-[52px] h-[52px] justify-center': !expanded,
      'py-3 px-3': expanded,
      '!text-white !fill-white': active,
      'text-gray-medium': !active,
    }
  );

  const iconClassName = classNames('transition-colors group-hover:text-white w-7 h-7', {
    'mr-2': expanded,
    'text-white': active,
    'text-medium-gray': !active,
  });

  const textClassName = classNames(
    'overflow-hidden font-medium leading-6 transition-colors group-hover:text-white whitespace-nowrap',
    {
      'text-medium-gray': !active,
      'text-white': active,
    }
  );

  const Icon = icon;

  return (
    <Tooltip text={!expanded ? children : undefined} placement="right-end">
      <Link {...linkProps} className={linkCassName}>
        {<Icon className={iconClassName} />}
        {expanded && <span className={textClassName}>{children}</span>}
      </Link>
    </Tooltip>
  );
};
