import { Providers } from '@components/Providers';
import config from '@config';
import {
  ContestModal,
  FreeVisitModal,
  getCookie,
  HowToUse,
  POST_LOGIN_REDIRECT_PATH,
  PromotionPopup,
  ReactFC,
  setCookie,
  useAuthentication,
  writeToSessionStorage,
} from '@milo/react';
import { default as qs } from 'query-string';
import { useEffect } from 'react';
import { Banner } from './Banner';
import { Footer } from './Footer';
import { Navigation } from './Navigation';

export type BaseLayoutProps = {
  colorScheme?: 'dark' | 'light';
  showNavigation?: boolean;
  showNavigationLogo?: boolean;
  transparentNavigation?: boolean;
  showFooter?: boolean;
  stickyNavigation?: boolean;
  showBanner?: boolean;
  guides?: boolean;
  saveRedirect?: boolean;
};

const USER_SOURCE_COOKIE_KEY = 'user_source';

export const BaseLayoutBase: ReactFC<BaseLayoutProps> = ({
  children,
  showBanner = true,
  showFooter = true,
  showNavigation = true,
  saveRedirect = true,
}) => {
  // Eventually gonna dynamically use and set the locale
  // const locale = 'fr'; // { locale } = useLocale();

  useEffect(() => {
    const cookie = getCookie(USER_SOURCE_COOKIE_KEY);
    const utmCampaign = qs.parse(location.search)?.utm_campaign as string;
    const utmSource = qs.parse(location.search)?.utm_source as string;

    const userSource = (utmCampaign ?? '') + '_' + (utmSource ?? '');

    if (userSource.length > 1 && !cookie) {
      setCookie(USER_SOURCE_COOKIE_KEY, userSource, { expires: { days: 30 } });
    }
  }, []);

  if (saveRedirect) writeToSessionStorage(POST_LOGIN_REDIRECT_PATH, location.pathname + location.search);

  const { user, loggedIn } = useAuthentication();
  const redirectToOnboardingPrepaidFlow =
    user?.has_pending_prepaid && !location.pathname.includes('/onboarding/prepaid');

  if (redirectToOnboardingPrepaidFlow) {
    location.replace('/onboarding/prepaid/welcome');
    return null;
  }

  return (
    <Providers>
      <div className={`relative mx-auto h-full min-h-[80vh] w-full animate-fade-in overscroll-auto`}>
        <PromotionPopup />

        {showBanner && <Banner />}

        {showNavigation && <Navigation />}

        <span className="animate-fade-in-top">
          <>{children}</>
        </span>
      </div>

      {config.APP_STAGE !== 'prd' && (
        <div className="fixed bottom-0 right-0 rounded-xl bg-white px-1 font-bold opacity-50">{config.APP_STAGE}</div>
      )}

      {showFooter && <Footer variant={loggedIn ? 'base' : 'full'} />}

      <HowToUse />

      <ContestModal />
    </Providers>
  );
};

export const BaseLayout: ReactFC<BaseLayoutProps> = (props) => {
  return (
    <Providers>
      <FreeVisitModal />
      <BaseLayoutBase {...props} />
    </Providers>
  );
};
