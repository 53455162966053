import { ReactFC } from '@milo/react';
import { BaseLayout, Footer } from '.';
import { BaseLayoutProps } from './BaseLayout';

export const OnboardingBaseLayout: ReactFC<BaseLayoutProps> = (props) => {
  return (
    <BaseLayout showFooter={false} {...props}>
      {props.children}
      <Footer variant="minimal" />
    </BaseLayout>
  );
};
