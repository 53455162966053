import { Trans } from '@lingui/macro';
import { AutumnLeaf, Link2, useFeatureFlag } from '@milo/react';

const AutumnBanner = () => {
  const { data: active, isLoading: loading } = useFeatureFlag('autumn-promo');

  if (loading || !active) return null;

  return (
    <div className="h-18 flex animate-fade-in-top flex-wrap items-center justify-center gap-4 bg-gray-400 p-3 text-center font-bold text-white">
      <p>
        <Trans>
          L'automne est là <AutumnLeaf className="inline-block w-14" />
          Découvrez le Québec et ses régions tout en économisant jusqu’au 31 octobre!
        </Trans>
      </p>
      <Link2
        href="/purchase?utm_source=autumn-banner"
        className="rounded-xl border-2 border-white p-1.5 transition-all duration-150 hover:bg-white hover:text-orange-400"
      >
        <Trans>J'en profite!</Trans>
      </Link2>
    </div>
  );
};

const BlackFridayBanner = () => {
  const { data: active, isLoading: loading } = useFeatureFlag('black-friday-2023');

  if (loading || !active) return null;

  return (
    <div className="h-18 flex animate-fade-in-top flex-wrap items-center justify-center gap-4 bg-black p-3 text-center font-bold text-white">
      <p>
        🌟 <Trans>Un Vendredi Fou Local avec M ta région: 70% de réduction sur le pass M ta région</Trans>
      </p>
      <Link2 href="/purchase?utm_source=black-friday-banner">
        <button className="rounded-xl border-2 border-white p-1.5 transition-all duration-500 hover:bg-white hover:text-black">
          <Trans>J'en profite!</Trans>
        </button>
      </Link2>
    </div>
  );
};

export const Banner = () => {
  const urlIsMiloFreePage = window.location.pathname.includes('/milo-gratuit');
  const urlIsPurchasePage = window.location.pathname.includes('/purchase');

  if (urlIsMiloFreePage || urlIsPurchasePage) return null;

  return (
    <>
      <BlackFridayBanner />
      <AutumnBanner />
    </>
  );
};
