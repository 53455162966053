import { t, Trans } from '@lingui/macro';
import {
  Button2,
  Field2,
  InputSelect2,
  InputText2,
  Modal,
  ModalBody,
  ModalProps,
  PaperAirplaneIcon,
  useGraphqlClient,
  useMutation,
  useQuery,
} from '@milo/react';
import { ApiResponse } from '@milo/types';
import { FC, FormEvent, useContext, useState } from 'react';
import { PartnerContext } from '../Layout';
import { ForgotCredentialsMutation } from './types';

export const ModalCreateAdminPassword: FC<ModalProps> = ({ onRequestClose = () => {}, ...props }) => {
  const { partnerUser: partner } = useContext(PartnerContext);
  const { client } = useGraphqlClient();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<string>();

  const {
    mutate: createAdminPassword,
    isLoading,
    isSuccess: sent,
  } = useMutation<ApiResponse, Error, ForgotCredentialsMutation>(
    async ({ email }) => {
      const response = await client.request(
        /* GraphQL */ `
          mutation PartnerCreateAdminPassword($input: EmailInput!) {
            PartnerCreateAdminPassword(input: $input) {
              success
            }
          }
        `,
        { input: { email } }
      );

      return response?.PartnerCreateAdminPassword;
    },
    {
      onMutate: () => {
        setEmailError(undefined);
      },
      onError: () => {
        setEmailError(
          'Ce courriel n’est attaché à aucun partenaire M ta région. Veuillez renseigner un autre courriel.'
        );
      },
    }
  );

  const { data: emails } = useQuery<string[]>(['partner-contacts'], async () => {
    const response = await client.request(/* GraphQL */ `
      query PartnerContactEmails {
        PartnerContactEmails {
          emails
        }
      }
    `);

    return response?.PartnerContactEmails.emails;
  });

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    createAdminPassword({ email });
  };

  const censoredEmail = email.split('@')[0] + '****';

  return (
    <Modal {...props} onRequestClose={onRequestClose}>
      <ModalBody className="!max-w-2xl">
        {sent ? (
          <div className="px-32 py-8">
            <PaperAirplaneIcon className="mx-auto h-16 w-16" />
            <h1 className="mb-3 mt-4 text-center text-[28px] font-bold leading-8">
              <Trans>Instructions envoyées</Trans>
            </h1>
            <p className="text-center text-medium-dark">
              {partner.has_admin_password ? (
                <Trans>
                  Un courriel vous a été envoyé sur <strong className="font-bold">{censoredEmail}</strong>. Suivez les
                  instructions fournies pour changer votre mot de passe administrateur.
                </Trans>
              ) : (
                <Trans>
                  Un courriel vous a été envoyé sur <strong className="font-bold">{censoredEmail}</strong>. Suivez les
                  instructions fournies pour créer votre mot de passe administrateur.
                </Trans>
              )}
            </p>
            <Button2
              variant="tertiary"
              type="submit"
              block
              onClick={() => createAdminPassword({ email })}
              className="mt-3"
            >
              <Trans>Renvoyer les instructions</Trans>
            </Button2>
          </div>
        ) : (
          <div className="px-32 py-24">
            {partner.has_admin_password ? (
              <>
                <h1 className="mb-3 text-[28px] font-bold leading-9">
                  <Trans>Réinitialisez votre mot de passe administrateur</Trans>
                </h1>
                <p className="text-medium-dark">
                  <Trans>
                    Un lien de réinitialisation de votre mot de passe administrateur et des instructions à suivre va
                    être envoyé.
                  </Trans>
                </p>
                <p className="mt-4 text-medium-dark">
                  <Trans>
                    Sélectionnez le courriel administrateur sur lequel recevoir le lien de réinitialisation.
                  </Trans>
                </p>
              </>
            ) : (
              <>
                <h1 className="mb-3 text-[28px] font-bold leading-9">
                  <Trans>Création du mot de passe administrateur</Trans>
                </h1>
                <p className="text-medium-dark">
                  <Trans>Un lien de création de mot de passe et des instructions à suivre va être envoyé.</Trans>
                </p>
                <p className="mt-4 text-medium-dark">
                  <Trans>Sélectionnez le courriel administrateur sur lequel recevoir le lien.</Trans>
                </p>
              </>
            )}

            <form onSubmit={onSubmit} className="mt-10">
              <Field2 errorText={emailError}>
                {emails ? (
                  <InputSelect2
                    placeholder={t`Choisir un courriel administrateur`}
                    value={email}
                    onValueChange={setEmail}
                    items={emails.map((email) => ({ key: email, label: email, value: email }))}
                  />
                ) : (
                  <InputText2
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    block
                    placeholder={t`Entrez le courriel administrateur`}
                  />
                )}
              </Field2>
              <Button2 type="submit" block disabled={email === ''} className="mt-3" loading={isLoading}>
                <Trans>Envoyer</Trans>
              </Button2>
            </form>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};
