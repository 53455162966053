import { Trans } from '@lingui/macro';
import { ArrowRight, formatCurrency } from '@milo/react';
import { Experience } from '@milo/types';
import { FC } from 'react';

type SavingsGridProps = {
  experience: Experience;
};

export const SavingsGrid: FC<SavingsGridProps> = ({ experience }) => {
  return (
    <div className="flex items-stretch justify-between w-full">
      <div className="flex flex-col justify-evenly">
        <p className="text-xl font-medium leading-6">
          <Trans>Achat minimum requis</Trans>
          <ArrowRight className="inline-flex w-3 ml-2" fill="currentColor" />
        </p>
        <p className="text-xl font-medium leading-6">
          <Trans>Privilège applicable</Trans>
          <ArrowRight className="inline-flex w-3 ml-2" fill="currentColor" />
        </p>
      </div>
      <div className="grid grid-cols-3 gap-3">
        <p className="text-[28px] leading-9 font-bold text-right">
          {formatCurrency(experience?.savings_matrix?.amount1 ?? 0)}
        </p>
        <p className="text-[28px] leading-9 font-bold text-right">
          {formatCurrency(experience?.savings_matrix?.amount2 ?? 0)}
        </p>
        <p className="text-[28px] leading-9 font-bold text-right">
          {formatCurrency(experience?.savings_matrix?.amount3 ?? 0)}
        </p>
        <p className="text-[28px] leading-9 font-bold text-right">
          {formatCurrency(experience?.savings_matrix?.savings1 ?? 0)}
        </p>
        <p className="text-[28px] leading-9 font-bold text-right">
          {formatCurrency(experience?.savings_matrix?.savings2 ?? 0)}
        </p>
        <p className="text-[28px] leading-9 font-bold text-right">
          {formatCurrency(experience?.savings_matrix?.savings3 ?? 0)}
        </p>
      </div>
    </div>
  );
};
